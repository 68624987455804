import React from "react";

type Props = {};

export default function ProjectComponent({}: Props) {
  return (
    <section className="project section" id="project">
      <h2 className="section_title">GOHEXA</h2>
      <span className="section_subtitle">My Hobbies Project - Gohexa Golang RESTful Service CLI</span>
      <div className="container grid">
	  <a
          className="project_info-name"
          href="https://github.com/billowdev/gohexa"
        >
          <img src="/static/images/gohexagonal-cli.png" alt="gohexa" className="project_img" />
        </a>
        <div className="youtube_data">
          <p className="youtube_description">
            GOHEXA is a robust code generator tailored for Golang projects,
            particularly useful when working with REST APIs using GORM and Fiber
            v2. Designed to streamline the development process, gohexa leverages
            the Hexagonal Architecture to help you efficiently scaffold and
            organize your applications. With gohexa, you can quickly set up new
            projects, modules, and components using well-defined templates that
            follow best practices in software design. It integrates seamlessly
            with GORM for database interactions and Fiber v2 for
            high-performance web frameworks, ensuring that your REST API
            development is both rapid and structured. This tool simplifies
            complex tasks, enabling you to focus on building features rather
            than managing boilerplate code.
          </p>
        </div>
      </div>
    </section>
  );
}
