import React from 'react'
import AboutLandingComponent from './components/About';
import ContactComponent from './components/Contact';
import HomeLandingComponent from './components/Home';
import NavbarLandingComponent from './components/Navbar';
import SkillsLandingComponent from './components/Skills';
import FooterComponent from './components/Footer';
import YoutubeLandingComponent from './components/Youtube';
import ProjectComponent from './components/Project';

type Props = {}

function LandingPage({}: Props) {
  return (
	<div>
		<NavbarLandingComponent />
		<HomeLandingComponent />
		<AboutLandingComponent />
		<SkillsLandingComponent />
		<ProjectComponent />
		<YoutubeLandingComponent />
		<ContactComponent />
		<FooterComponent />
	</div>
  )
}

export default LandingPage