import React, { useEffect } from "react";

type Props = {};

export default function SkillsLandingComponent({}: Props) {
  useEffect(() => {
    const skillsContent = document.getElementsByClassName(
      "skills_content"
    ) as HTMLCollectionOf<HTMLElement>;
    const skillsHeader = document.querySelectorAll(".skills_header");

    function toggleSkills(this: HTMLElement): void {
      let itemClass = (this.parentNode as HTMLElement)?.className;

      for (let i = 0; i < skillsContent.length; i++) {
        skillsContent[i].className = "skills_content skills_close";
      }

      if (itemClass === "skills_content skills_close") {
        (this.parentNode as HTMLElement)!.className =
          "skills_content skills_open";
      }
    }

    skillsHeader.forEach((el) => {
      el.addEventListener("click", toggleSkills);
    });

    // cleanup function to remove event listeners
    return () => {
      skillsHeader.forEach((el) => {
        el.removeEventListener("click", toggleSkills);
      });
    };
  }, []);

  const technicalSkillSet = [
    "• HTML CSS JavaScript Tailwind Ant Design &Material UI",
    "• PHP Laravel Framework",
    "• React, NextJS & Redux Toolkit",
    "• NodeJS Express & Fastify",
    "• Angular & NestJS TypeScript",
    "• Sequelize, TypeORM & Prisma",
    "• Golang & Fiber HTTP",
    "• Python & Django",
    "• C# Dot Net Framework Window Application",
    "• Basic C# Reverse Engineering",
    "• Dart & Flutter",
    "• Redis, Kafka, PostgreSQL, MySQL, MongoDB",
    "• Docker, Git & GitHub",
    "• Gitlab CICD, Jenkins & Teamcity",
    "• Go Temporal, Python Celery",
  ];
  const softSkillSet = [
    "• Communication",
    "• Growth Mindset",
    "• Can-do Attitude",
    "• Self Learning & Adaptability",
    "• Teaching & Coaching",
  ];
  const otherSkillSet = [
    "• Data structure & Algorithm",
    "• System Analysis & Design",
    "• Object Oriented Analysis and Design",
    "• Solid Foundation & Design Pattern",
    "• Dependency Injection Pattern",
    "• Hexagonal Architecture",
    "• Jmeter Performance Testing",
    "• Linux Server",
    "• AWS Cloud, Azure Storage & Digital Ocean ",
    "• Chat GPT and AI Tools",
    "• Knowledge of Microservices",
    "• Mobile Application Deployment",
    "• Android Google Play Console",
    "• IOS Apple Developer Program",
    "• Crafting Documentation, API Specificatio",
  ];
  const communicationLanguage = ["• Thai - Native", "• English - Intermediate"];
  return (
    <section className="skills section" id="skills">
      <h2 className="section_title">Skills</h2>
      <span className="section_subtitle">
        I have a solid foundation in programming languages such as Python Golang
        and TypeScript
      </span>
      <div className="skills_container container grid">
        <div>
          {/* Skills 1 */}
          <div className="skills_content skills_open">
            <div className="skills_header">
              <i className="uil uil-brackets-curly skills_icon" />
              <div>
                <h1 className="skills_title"> Technical Skill</h1>
              </div>
              <i className="uil uil-angle-down skills_arrow" />
            </div>
            {technicalSkillSet.map((el) => {
              return (
                <div className="skills_list grid">
                  <div className="skills_data">
                    <div className="skills_titles">
                      <h3 className="skills_name">{el}</h3>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* Skills 2 */}
          <div className="skills_content skills_close">
            <div className="skills_header">
              <i className="uil uil-swatchbook skills_icon" />
              <div>
                <h1 className="skills_title"> Soft Skill</h1>
                {/* <span class="skills_subtitle">More than 1 years</span> */}
              </div>
              <i className="uil uil-angle-down skills_arrow" />
            </div>
            {softSkillSet.map((el) => {
              return (
                <div className="skills_list grid">
                  <div className="skills_data">
                    <div className="skills_titles">
                      <h3 className="skills_name">{el}</h3>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div>
          <div>
            {/* Skills 3 */}
            <div className="skills_content skills_close">
              <div className="skills_header">
                <i className="uil uil-server-network skills_icon" />
                <div>
                  <h1 className="skills_title">Other</h1>
                  {/* <span class="skills_subtitle">More than 1 years</span> */}
                </div>
                <i className="uil uil-angle-down skills_arrow" />
              </div>
              {otherSkillSet.map((el) => {
                return (
                  <div className="skills_list grid">
                    <div className="skills_data">
                      <div className="skills_titles">
                        <h3 className="skills_name"> {el}</h3>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div>
            {/* Skills 4 */}
            <div className="skills_content skills_close">
              <div className="skills_header">
                <i className="uil uil-swatchbook skills_icon" />
                <div>
                  <h1 className="skills_title">Communication Language</h1>
                </div>
                <i className="uil uil-angle-down skills_arrow" />
              </div>
              {communicationLanguage.map((el) => {
                return (
                  <div className="skills_list grid">
                    <div className="skills_data">
                      <div className="skills_titles">
                        <h3 className="skills_name">{el}</h3>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
