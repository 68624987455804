import React from 'react'
import "../../../styles/style.css"
import { useRef } from 'react';
type Props = {}

export default function ContactComponent({ }: Props) {
	const buttonRef = useRef(null);

	const handleClick = () => {
		console.log('Button clicked!');
	  };
	
	return (
	<section className="contact section" id="contact">
  <h2 className="section_title">Contact Me</h2>
  <span className="section_subtitle">Get in Touch</span>
  <div className="contact_container container grid">
    <div>
      <div className="contact_information">
        <i className="uil uil-user contact_icon" />
        <div>
          <h3 className="contact_title">Name</h3>
          <span className="contact_subtitle">Akkarapon Phikulsri</span>
        </div>
      </div>
      <div className="contact_information">
        <i className="uil uil-user contact_icon" />
        <div>
          <h3 className="contact_title">Gravatar</h3>
          <a href="https://akkarapon.link/"><span className="contact_subtitle">https://akkarapon.link</span></a>
        </div>
      </div>
      <div className="contact_information">
        <i className="uil uil-envelope contact_icon" />
        <div>
          <h3 className="contact_title">Message</h3>
          <span className="contact_subtitle">akkarapon@rapidstellar.com</span>
        </div>
      </div>
      <div className="contact_information">
        <i className="uil uil-map-marker contact_icon" />
        <div>
          <h3 className="contact_title">My country</h3>
          <span className="contact_subtitle">Thailand</span>
        </div>
      </div>
    </div>
    <form action="https://formspree.io/f/xbjwjwlb" method="post" className="contact_form grid">
      <div className="contact_inputs grid">
        <div className="contact_content">
          <label className="contact_label">Name</label>
          <input type="text" name="name" className="contact_input" />
        </div>
        <div className="contact_content">
          <label className="contact_label">Email</label>
          <input type="email" name="_replyto" className="contact_input" />
        </div>
        <div className="contact_content">
          <label className="contact_label">Title</label>
          <input type="text" name="title" className="contact_input" />
        </div>
        <div className="contact_content">
          <label className="contact_label">Message</label>
          <textarea name="message" cols={0} rows={7} className="contact_input" defaultValue={""} />
        </div>

        <div>
          <button  ref={buttonRef} className="button button--flex button--contact" type="submit" value="Send">
            Send Message
            <i className="uil uil-message button_icon" />
          </button>
        </div>

      </div>
    </form>
  </div>
</section>


	)
}