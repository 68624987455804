import React from 'react'

type Props = {}

export default function FooterComponent({ }: Props) {
	return (
		<footer className="footer">
			<div className="footer_bg">
				<div className="footer_container container grid">
					<div>
						<h1 className="footer_title">BillowDev</h1>
						<span className="footer_subtitle">Akkarapon Phikulsri</span>
					</div>
					<ul className="footer_links">
						<li>
							<a href="#about" className="footer_link">About</a>
						</li>
						<li>
							<a href="#skills" className="footer_link">Skills</a>
						</li>
						<li>
							<a href="#contact" className="footer_link">Contact</a>
						</li>
					</ul>
					<div className="footer_socials">
						<a href="https://www.linkedin.com/in/akkarapon/" target="_blank" className="footer_social">
							<i className="uil uil-linkedin" />
						</a>
						<a href="https://www.github.com/billowdev" target="_blank" className="footer_social">
							<i className="uil uil-github" />
						</a>
						<a href="https://www.youtube.com/@billowdev" target="_blank" className="footer_social">
							<i className="uil uil-youtube" />
						</a>
					</div>
				</div>
				<p className="footer_copy" style={{ color: '#fff' }}> © {new Date().getFullYear()}  BillowDev All Rights Reserved</p>
			</div>
		</footer>

	)
}