import React, { useEffect, useState } from 'react'
import "../../../styles/style.css"
type Props = {}

export default function NavbarLandingComponent({ }: Props) {


  return (
    <nav className="nav container">
      <a href="#" className="nav_logo">
        {/* <img className="logo" src="/static/images/logo-h.png" width="100px" alt="lacakp_logo" /> */}
       Akkarapon Phikulsri
      </a>
      <div className="nav_menu" id="nav-menu">
        <ul className="nav_list grid">
          <li className="nav_item">
            <a href="#home" className="nav_link">
              <i className="uil uil-estate nav_icon" /> Home
            </a>
          </li>
          <li className="nav_item">
            <a href="#about" className="nav_link">
              <i className="uil uil-user nav_icon" /> About
            </a>
          </li>
          <li className="nav_item">
            <a href="#skills" className="nav_link">
              <i className="uil uil-file-alt nav_icon" /> Skills
            </a>
          </li>
          {/* <li class="nav_item">
							<a href="#services" class="nav_link">
								<i class="uil uil-briefcase-alt nav_icon"></i> Services
							</a>
						</li> */}
          {/* <li class="nav_item">
							<a href="#portfolio" class="nav_link">
								<i class="uil uil-scenery nav_icon"></i> Portfolio
							</a>
						</li> */}
          {/* <li class="nav_item">
							<a href="#certificates" class="nav_link">
								<i class="uil uil-award nav_icon"></i> Certificates
							</a>
						</li> */}
          <li className="nav_item">
            <a href="#contact" className="nav_link">
              <i className="uil uil-message nav_icon" /> Contact
            </a>
          </li>
        </ul>
        <i className="uil uil-times nav_close" id="nav-close" />
      </div>
        {/* btn change theme */}
      {/* <div className="nav_btns">
        <i className="uil uil-moon change-theme" id="theme-button" />
        <div className="nav_toggle" id="nav-toggle">
          <i className="uil uil-apps" />
        </div>
      </div> */}
      <div className="progressContainer">
        <div className="progressBar" />
      </div></nav>

  )
}