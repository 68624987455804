import React from 'react'

type Props = {}
export default function YoutubeLandingComponent({ }: Props) {

	
	return (
		<section className="youtube section" id="youtube">
			<h2 className="section_title">BillowDev</h2>
			<span className="section_subtitle">Youtube Chanel BillowDev</span>
			<div className="youtube_container container grid">
				<a className="youtube_info-name" href="https://www.youtube.com/@billowdev"><img src="/static/images/_logo-500x500.png" className="youtube_img" /></a>
				<div className="youtube_data">
					<p className="youtube_description">
						BillowDev Chanel That is one of my hobbies is creating helpful coding tutorials that cover everything from configuration to installation. The main idea behind my channel is that teaching is the best way to learn.
					</p>
				

				</div>
			</div>
		</section>

	)
}