import React from "react";

type Props = {};
export default function AboutLandingComponent({}: Props) {
  return (
    <section className="about section" id="about">
      <h2 className="section_title">About Me</h2>
      <span className="section_subtitle">My introduction</span>
      <div className="about_container container grid">
        <img src="/static/images/me-2.png" className="about_img" />
        <div className="about_data">
          {/* <p className="about_description">You can call me LAC</p> */}
          <div className="about_description">
            {/* <p>You can call me Lac</p>
            <p>L-ogic</p>
            <p>A-lgorithm</p>
            <p>C-ollaboration</p> */}
        
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;✅ A software craftsman passionate about exploring new
              technologies and creating innovative solutions to solve business
              problems.
            </p>
            <p>
			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;✅ I have a solid foundation in programming languages such as
              Python Golang and TypeScript, My expertise extends to software design patterns, enabling
              me to develop robust and scalable applications. Knowledge about
              distributed system Microservices.
            </p>
			<p>
			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;✅ I pride myself on my strong ability to adapt and learn quickly,
              traits that have consistently contributed to my success in
              tackling challenging projects and staying abreast of the latest
              technological advancements. I am eager to leverage my skills and dedication to contribute
              to innovative software solutions.
            </p>
          
          </div>
          {/* <div className="about_info">
						<div>
							<span className="about_info-title">-</span>
							<span className="about_info-name">Years <br /> experience</span>
						</div>
						<div>
							<span className="about_info-title">05+</span>
							<span className="about_info-name">Completed <br /> project</span>
						</div>
						<div>
							<span className="about_info-title">-</span>
							<span className="about_info-name">Companies <br /> worked</span>
						</div>
					</div> */}
          {/* <div class="about_buttons">
					<a href="#certificates" class="button button--flex">
						Certificates<i class="uil uil-arrow-down button_icon"></i>
					</a>
				</div> */}
        </div>
      </div>
    </section>
  );
}
